<div class="container">
<h2 class="main-title">
    Convert Word to PDF Online
</h2>
<p class="main-description">
    The most popular online converter is PDF Magic. Every day, we help millions <br>
     of users in converting Word files to the convenient PDF format.
</p>
<div class="advantages-container">

    <div class="advantages-row ">
        <div class="advantage-text">
            <h3>
                Easy Word to PDF Conversion
            </h3>
            <p>
                You can use this useful tool to convert any Word document to PDF online in an efficient and accurate manner.
                 The layout and formatting are identical.
            </p>
        </div>
        <img src="../../../assets/images/advantages1.png" alt="">
        
    </div>

    <div class="advantages-row">
        <div class="advantage-text">
            <h3>
                Share Converted Files with Others
            </h3>
            <p>
                Once you convert a Word file to PDF—use our share feature
                 to quickly create a shareable download link or email the PDF via a simple click.
            </p>
        </div>
        <img src="../../../assets/images/advantages2.png" alt="">
        
    </div>

    <div class="advantages-row ">
        <div class="advantage-text">
            <h3>
                Improve Your PDFs
            </h3>
            <p>
                Use our other tools to further modify your PDF before saving it. 
                You can add text and annotations, compress it for emailing, and even merge it with other PDFs to make file management easier.
            </p>
        </div>
        <img src="../../../assets/images/advantages3.png" alt="">
        
    </div>
</div>
</div>