<div class="main-wrapper">
  <ngx-dropzone class="converter-wrapper" (change)="onDrop($event)" disableClick [ngStyle]="{'display': (progress == 'init') ? 'block' : 'none'}">
      <div class="upload">
        <img src="../../../assets/images/input-file-bg.png" />
        <div class="app-title">Drag &amp; Drop File Here</div>
        <div class="or-container"><div class="line"></div><p > or </p><div  class="line"></div></div>
        <button type="button" (click)="onClick()" class="button-standard">
          {{ content.chooseFilesButtonText }}
          <img class="choose-file-button-img" src="../../../assets/images/file-icon.svg">
        </button>
        <p class="lock-text" (click)="showSecurityPopup()">
          <img src="../../../assets/images/lock.svg" />
          {{ content.lockText }}
        </p>
        <div [ngClass]="{'secured-popup': true, 'hide': popupStatus == false}">{{ content.lockTextPopup }}</div>
      </div>
      <input type="file"  #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
    </ngx-dropzone>
    
      <div class="progress main-area" *ngIf="progress == 'uploading'">
        <div class="progress-status" #progressStatus >{{ content.spinnerMessageText }}</div>
        <img class="progress-animation" src="../../../assets/images/progress.gif"  alt="">
        <div class="cancel-progress" (click)="taskProgress='cancel'">Cancel</div>
      </div>
    
    <div class="alert" [ngStyle]="{'display': (progress == 'cancel') ? 'block' : 'none'}">
      <div class="alert-container">
       <img src="../../../assets/images/warning.svg" >
        <div class="alert-text">Are you sure you want to 
          cancel conversion?</div>
        <div class="buttons-wrapper cancel-buttons">
          <button type="button" class=" pdf-button standard-button pdf-button-small" (click)="clearScreen('init')">Yes</button>
          <button type="button" class=" pdf-button standard-button pdf-button-small pdf-button-transparent" (click)="taskProgress='uploading'">No</button>
        </div>
      </div>
    </div>
    
    
    <div class="alert" [ngStyle]="{'display': (progress == 'error-occurred') ? 'block' : 'none'}">
      <div class="alert-container">
        <img src="../../../assets/images/warning.svg" >
        <div class="alert-text">
          Error occurred
          <div class="alert-subtext">Please, try again</div>
        </div>
        
        <div class="buttons-wrapper error-alert-buttons">
          <button type="button" class="pdf-button standard-button" (click)="clearScreen('init')">Start over</button>
        </div>
      </div>
    </div>
    
    <div class="alert" [ngStyle]="{'display': (progress == 'error-alert') ? 'block' : 'none'}">
      <div class="alert-container">
        <img src="../../../assets/images/warning.svg" >
        <div class="alert-text">This file type you have selected is not supported</div>
        
        <div class="buttons-wrapper error-alert-buttons">
          <button type="button" class="pdf-button standard-button" (click)="clearScreen('init')">Start over</button>
        </div>
      </div>
    </div>
    
    <div class="download" [ngStyle]="{'display': (progress == 'ended') ? 'flex' : 'none'}">
      <img src="../../../assets/images/done.gif" /> 
      <div class="download-headline">Your file has been
        downloaded</div>
      <div class="download-success">
        if you do not see the download, <span class="try-again" (click)="clearScreen('init')">try again.</span>
      </div>
      <button type="button" class="pdf-button standard-button start-over" (click)="clearScreen('init')">Start over</button>
    </div>
  </div>
<div>
