import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ConvertingService } from '../../converting.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfCompnent implements OnInit {
  // progressChanged: EventEmitter<string> = new EventEmitter();
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef
  @ViewChild('progressLabel', { static: false }) progressLabel: ElementRef
  @ViewChild('progressStatus', { static: false }) progressStatus: ElementRef
  @ViewChild('progressBar', { static: false }) progressBar: ElementRef
  private _taskProgress: string = 'init'
  private progress_options = ['init', 'uploading', 'cancel', 'error-occurred', 'not-supported', 'error-alert', 'ended']
  sub: Array<Subscription> = [];
  intervalRef = [];
  inProgress: boolean = false;
  rejected: File[] = [];
  rejected_ext: String[] = []
  rejected_error: string = null;
  files = []

  content = {
    appTitleText: 'Convert a file from and to PDF',
    appGuidlineText: 'Drag a file here or',
    chooseFilesButtonText: 'Select File',
    spinnerMessageText: 'Converting...',
    successMessageText: 'We have successfully converted your file!',
    lockText: 'Your files are secure',
    lockTextPopup: 'Your files are only stored on our servers for 24 hours, after which they are permanently destroyed'
  }

  popupStatus: boolean = false;

  constructor(private convertingService: ConvertingService) { }

  ngOnInit() {
    
  }

  private uploadFiles() {
    this.taskProgress = 'uploading'

    const response = this.convertingService.startConvert(this.files);
    // console.log(response)
    if (response.error) {
      this.clearScreen(response.error === 'not-supported' ? 'error-alert' : 'error-occured')
    } else {
      const s = response.subscribtion.subscribe(result => {
        if (this.progress == 'uploading') {
          if (result['success'] == true) {
            this.downloadFile(result)
            Object.values(response.rejected).forEach((file: File, index) => {
              this.rejected_ext.push(file.name.split('.').pop())
            });
            this.rejected_ext = this.rejected_ext.filter((v, i, a) => a.indexOf(v) === i);
            if (this.rejected_ext.length) {
              this._taskProgress = 'error-alert';
              this.setRejectedMessage();
            }
            
          } else {
            this.clearScreen('error-occurred')
          }
        }
      })
      this.sub.push(s)
    }
  }

  setRejectedMessage() {
    switch (true) {
      case this.rejected_ext.length === 1:
        this.rejected_error = `* File format .${this.rejected_ext[0]} is not supported`
        break;
      case this.rejected_ext.length === 2:
        this.rejected_error = `* File formats .${this.rejected_ext[0]} and .${this.rejected_ext[1]} are not supported`
        break;
      case this.rejected_ext.length > 2:
        this.rejected_error = `* File formats .${this.rejected_ext[0]}, .${this.rejected_ext[1]} and more are not supported`
        break;
      default:
        this.rejected_error = null;
        break;
    }
  }

  clearScreen(progress: string) {
    // console.log('progress', progress);
    if (progress && this.progress_options.includes(progress)) {
      this.taskProgress = progress
    }

   
    this.files = [];
    this.setRejectedMessage();
    this.rejected_ext = [];
    this.fileUpload.nativeElement.value = null;

    Object.values(this.sub).forEach((value: Subscription, index) => {
      value.unsubscribe()
    })
    this.sub = []

    while (this.intervalRef.length) {
      // console.log('interval clear');
      this.intervalRef.forEach(element => clearInterval(element));
      this.intervalRef.pop()
    }
    this.inProgress = false;
  }

  set taskProgress(val: string) {
    this._taskProgress = val;

  }

  get progress(): string {
    return this._taskProgress;
  }

  private downloadFile(file) {
    const inIFrame = (window.location != window.parent.location);
    if (inIFrame) {
      window.parent.postMessage(
        {
          event_id: 'conversionComplete',
          params: {
            url: file["file"]["url"],
            name: file["file"]["filename"]
          }
        },
        "*"
      );
    }
    else {
      window.location = file["file"]["url"];
    }
    this.files = []
    this.taskProgress = 'ended'
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();

    fileUpload.onchange = () => {
      if (fileUpload.files.length > 0) {
        this.files.push(...fileUpload.files);
        this.uploadFiles();
      }
    }
  }

  onDrop(event) {
    if (event.addedFiles) {
      if (event.addedFiles.length > 0) {
        this.files.push(...event.addedFiles);
        this.uploadFiles();
      }
    }
  }
  
  showSecurityPopup() {
    this.popupStatus = !this.popupStatus;
  }
}
