import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ConvertingService } from './converting.service';
import { HeaderComponent } from './components/header/header.component';
import { AdvantagesComponent } from './components/advantages/advantages.component';
import { AppComponent } from './components/app/app.component';
import { PdfCompnent } from './components/pdf/pdf.component';
import { BannerComponent } from './components/banner/banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AdvantagesComponent,
    PdfCompnent,
    BannerComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    NgxDropzoneModule,
    HttpClientModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-6495373596935145'
    }),
  ],
  providers: [ConvertingService],
  bootstrap: [AppComponent]
})
export class AppModule { }