
  <div class="container">
<header class="header">

  <div class="header-left">
    <img src="../../../assets/images/logo.svg" />
    <h2>
      PDF Magic
    </h2>
  </div>
  <p class="header-right">
    Convert PDF Files To Doc Files And Vice-Versa
  </p>

</header>
</div>