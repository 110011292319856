<div class="container">
    <footer class="footer">
        <div class="links">
            <a href="https://www.pdfmagiconline.com/privacy">
                Privacy
            </a>
            <a href="https://www.pdfmagiconline.com/eula">
                EULA
            </a>
            <a href="https://www.pdfmagiconline.com/contact">
                Contact
            </a>
            <a href="https://www.pdfmagiconline.com/blog">
                Blog
            </a>
        </div>
        <p>
            © PDF Magic, 2015–2022
        </p>
        <p>

        </p>
    </footer>
</div>