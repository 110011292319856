import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from  '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ConvertingService {
  public convertToFiles: String[] = ["bmp", "odd", "svg", "ps", "lrf", "html", "pdb", "emf", 
  "mobi", "dxf", "txt", "oeb", "wmf", "azw3", "jpg", "ico", "webp", "pdf", "gif", "psd", 
  "rtf", "tiff", "png", "epub", "doc", "docx", "eps", "xlsx", "ppt", "pptx"]

  public mergeToFiles: String[] = ["htm","azw4","cdr","arw","bmp","mobi","sk","lwp",
  "dwg","eps","lrf","cgm","3fr","pages","pptx","xps","gif","rst","docx","pptm","rtf",
  "jpeg","vsd","dcr","sdw","abw","ppt","csv","raf","htmlz","ps","erf","odt","wpd","wps",
  "odd","sk1","dxf","txtz","pdb","pot","psd","prc","ico","pef","numbers","xcf","pps","x3f","mos",
  "snb","svg","docm","pdf","potx","fb2","html","pml","svgz","ods","doc","heic","dot",
  "raw","azw","xlsx","dng","et","orf","wmf","dotx","sdc","odp","cbc","lit","ppm","hwp",
  "jpg","tex","sda","azw3","nef","txt","tiff","dps","djvu","emf","cbr","xlsm","rb","mrw",
  "crw","ai","cr2","key","xls","zabw","ppsx","epub","tif","webp","cbz","chm","tcr","md","png"]

  private settings = {
        service_url: 'https://service.pdfmagiconline.com/',
        path: {
          convert: 'api/convert/',
          merge: 'api/merge/'
        }
  };

  constructor(private httpClient: HttpClient) {}

  startConvert(files_list: File[]) {
    const result = {error: null, subscribtion: null, rejected: []};

    if(files_list.length === 1) {
      const ext = files_list[0].name.split('.').pop()
      if(this.convertToFiles.includes(ext)) {
        result.subscribtion = this.convertFile(files_list[0])
      } else {
        result.error = 'not-supported'
        result.rejected.push(files_list[0])
      }
    } else if(files_list.length > 1) {
      const filtered: Array<File> = [];
      Object.values(files_list).forEach((value:File, index)=> {
        if(this.mergeToFiles.includes(value.name.split('.').pop())) {
          filtered.push(value)
        } else {
          result.rejected.push(value)
        }
      });

      if(filtered.length > 0) {
        if(filtered.length == 1) {
          const ext = filtered[0].name.split('.').pop()
          if(this.convertToFiles.includes(ext)) {
            result.subscribtion = this.convertFile(files_list[0])
          } else {
            result.error = 'not-supported'
            result.rejected.push(files_list[0])
          }
        } else {
          result.subscribtion = this.mergeToPdf(filtered)
        }
      } else {
        result.error = 'not-supported'
      }
    } else {
      result.error = 'not-supported'
    }

    return result
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string;

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //return throwError(errorMessage);
    return errorMessage
  }

  private mergeToPdf(files: any) {
    // console.log("mergeToPdf");

    let formData: FormData = new FormData();
    
    Object.values(files).forEach((value:File, index)=> {
      formData.append(`file${index+1}`, value, value.name);
    });
    return this.httpClient
    .post<any>(this.settings.service_url + this.settings.path.merge + files.length, formData)
    .pipe(catchError(this.handleError));
  }

  private convertFile(file:File) {
    let to_type = "pdf"
    let formData: FormData = new FormData();
    formData.append(`file`, file, file.name);

    if(file.type == 'application/pdf') {
      to_type = "doc"
    }
    
    return this.httpClient
    .post<any>(this.settings.service_url + this.settings.path.convert + to_type, formData)
    .pipe(catchError(this.handleError));
  }
}