<div class="page-wrapper">
    <app-header>
  
    </app-header>
    <h1 class="main-title">
      PDF Converter Tool
    </h1>
    <p class="main-description">
      Convert files from and to PDF
    </p>
    
    <div class="ads-row-wrapper">
      <div class="ads-banner no-spacing">
        <ng-adsense
          [adClient]="'ca-pub-6495373596935145'"
          [adSlot]="8695285308"
          [display]="'block'"
          [adFormat]="'auto'"
          [fullWidthResponsive]="false"
          [width]="250"
          [height]="500"
        ></ng-adsense>
      </div>
      <app-pdf></app-pdf>
      <div class="ads-banner no-spacing">
        <ng-adsense
          [adClient]="'ca-pub-6495373596935145'"
          [adSlot]="3446031896"
          [display]="'block'"
          [adFormat]="'auto'"
          [fullWidthResponsive]="false"
          [width]="250"
          [height]="500"
        ></ng-adsense>
      </div>
    </div>
  
    <div class="ads-row-wrapper">
      <div class="ads-banner">
        <ng-adsense
          [adClient]="'ca-pub-6495373596935145'"
          [adSlot]="4998786717"
          [display]="'block'"
          [adFormat]="'auto'"
          [fullWidthResponsive]="false"
          [width]="250"
          [height]="500"
        ></ng-adsense>
      </div>
      <app-advantages></app-advantages>
      <div class="ads-banner">
        <ng-adsense
          [adClient]="'ca-pub-6495373596935145'"
          [adSlot]="6807488561"
          [display]="'block'"
          [adFormat]="'auto'"
          [fullWidthResponsive]="false"
          [width]="250"
          [height]="500"
        ></ng-adsense>
      </div>
    </div>
  
    <app-banner></app-banner>
    <app-footer></app-footer>
  </div>
